import { cancelRecurringDonation } from '@/api/recurringDonations/cancel'

const actions = {
  async cancelRecurringDonation ({ _ }, id) {
    const val = {
      recurringDonationID: id,
      reason: 'Cancel'
    }
    try {
      const cancelReturn = await cancelRecurringDonation(val)
      return cancelReturn
    } catch (error) {
      console.error('ERRR', error)
      return 'Error'
    }
  }
}

export default {
  actions
}
