<template>
  <div class="w-100" >
    <div class="gradient">
      <div class="abs_nav" :class="{'d-none d-lg-block': loginPopup, 'home_overlay': isHome, 'top-env-padding': isApp}">
        <div class="container-xl">
          <div class="row align-items-center nav-holder">
            <TopMenuLayoutMobile />
            <TopMenuLayoutDesktop />
          </div>
        </div>
      </div>
      <HomeBackgroundTop v-if="isHome" />
      <BackgroundTop v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import HomeBackgroundTop from '@/pages/home/views/slider/HomeBackgroundTop.vue'
import TopMenuLayoutDesktop from './views/TopMenuLayoutDesktop.vue'
import TopMenuLayoutMobile from './views/TopMenuLayoutMobile.vue'

export default {
  components: {
    BackgroundTop: defineAsyncComponent(() => import('./components/BackgroundTop.vue')),
    HomeBackgroundTop,
    TopMenuLayoutDesktop,
    TopMenuLayoutMobile
  },
  name: 'TopNav',
  computed: {
    ...mapGetters([
      'loginPopup', 'isApp'
    ]),
    isHome () {
      if (this.$route.name === 'Home') {
        return true
      }
      return false
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    isZA () {
      if (process.env.VUE_APP_COUNTRY === 'ZA') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>

<style scoped>
.nav-holder {
  min-height: 100px;
}
.gradient {
  position: relative;
  background-color: transparent;
  /* background-image: linear-gradient(180deg, #000000C7 0%, #00000000 61%); */
  background-color: #03191300;
}
.abs_nav  {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
}
.revertOffset {
  top: 40px
}
.home_overlay {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 91%);
}
@media (min-width: 992px) {
  .revertOffset {
    top: 48px
  }
  .nav-holder {
    min-height: 90px;
  }
}
</style>
