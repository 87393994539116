import axios from 'axios'

const getNews = (start, size) => {
  const startPosition = start || 0
  const pageSize = size || 10
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/news`, {
    params: {
      startPosition,
      pageSize,
      sortField: 'priority',
      sortOrder: 'DESC'
    }
  })
}

export { getNews }
