import { getFundraisers, getFundraisersCount } from '@/api/verifiedFundraisers'

const state = {
  verifiedFundraisers: [],
  verifiedFundraisersCount: 0
}
const mutations = {
  SET_FUNDRAISERS (state, val) {
    state.verifiedFundraisers = val
  },
  SET_FUNDRAISERS_COUNT (state, val) {
    state.verifiedFundraisersCount = val
  }
}
const actions = {
  async getFundraisers ({ commit, state }) {
    const theFundraisers = await getFundraisers(0, state.verifiedFundraisersCount)
    if (theFundraisers.data) {
      await commit('SET_FUNDRAISERS', theFundraisers.data)
    }
  },
  async getFundraisersCount ({ commit, dispatch }) {
    const theCount = await getFundraisersCount()
    if (theCount) {
      await commit('SET_FUNDRAISERS_COUNT', theCount.data)
      if (theCount.data > 0) {
        await dispatch('getFundraisers')
      }
    }
  }
}

const getters = {
  verifiedFundraisers: state => state.verifiedFundraisers
}

export default {
  state,
  mutations,
  actions,
  getters
}
