import { saveDailySadaqah, saveDailySadaqahAnon } from '@/api/dailySadaqahZA'
import { saveRecurringDonation } from '@/api/recurringDonations/save'
import { DateTime } from 'luxon'

const actions = {
  async saveSadaqah ({ rootState }, val) {
    if (rootState.user.user) {
      try {
        const dailySadaqah = await saveDailySadaqah(val)
        if (dailySadaqah && dailySadaqah.status === 200) {
          return dailySadaqah
        }
      } catch (error) {
        console.error('ERRR', error)
      }
    } else {
      try {
        const dailySadaqah = await saveDailySadaqahAnon(val)
        if (dailySadaqah && dailySadaqah.status === 200) {
          return dailySadaqah
        }
      } catch (error) {
        console.error('ERRR', error)
      }
    }
  },
  async saveSadaqahUk ({ rootState }, val) {
    const dateNow = DateTime.now().toISO()
    const giftAid = rootState.user.user.giftAid ? rootState.user.user.giftAid : false
    const saveDetails = {
      startDate: dateNow,
      frequency: 'DAILY',
      debitOrderDay: 1,
      dailySadaqaDonation: true,
      recurringDonationType: 'CREDIT_CARD',
      untilCancelled: true,
      totalDailySadaqah: val.totalDailySadaqah,
      transactionDTO: {
        userID: val.userID ? val.userID : 0,
        giftAid,
        organisationID: val.organisationID ? val.organisationID : 0,
        sponsored: true
      }
    }
    try {
      const dailySadaqah = await saveRecurringDonation(saveDetails)
      if (dailySadaqah && dailySadaqah.status === 200) {
        return dailySadaqah
      }
    } catch (error) {
      console.error('ERRR', error)
    }
  }
}

export default {
  actions
}
