<template>
  <div class="back" >
    <span v-touch:swipe="swipeHandler">
      <div class="bg_overlay"></div>
      <Slider :visiblePosters="visiblePosters" ref="slider" v-if="!noPosters"/>
      <div class="shape" @click="goToProject">
        <div class="position-absolute top-0 start-50 translate-middle mt-5 white-text" v-if="noPosters">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <path class="elementor-shape-fill" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
        </svg>
      </div>
      <div class="shape_inner" >
      </div>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Slider: defineAsyncComponent(() => import('./Slider.vue'))
  },
  name: 'HomeBackgroundTop',
  async mounted () {
    if (this.noPosters) {
      await this.fetchSliderPosters()
    }
  },
  computed: {
    ...mapGetters([
      'visiblePosters',
      'activeSlider'
    ]),
    noPosters () {
      if (!this.visiblePosters || (this.visiblePosters && this.visiblePosters.length < 1)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchSliderPosters'
    ]),
    goToProject () {
      this.$router.push('/project/' + this.activeSlider.project.id)
    },
    swipeHandler (direction) {
      if (direction === 'right') {
        this.$refs.slider.previous()
      } else if (direction === 'left') {
        this.$refs.slider.next()
      }
    }
  }
}
</script>

<style scoped>
  .back {
    background-color: transparent;
    background-image: linear-gradient(180deg, #0D351C 0%, #1A6836 70%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    z-index: 0;
    position: relative;
    height: 620px;
    margin-bottom: 0;
  }
  .bg_overlay {
    background-image: url(../../../../assets/images/p.png);
    background-size: 181px auto;
    opacity: 0.05;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    bottom: -1px;
  }
  .shape svg {
    width: calc(251% + 1.3px);
    height: 500px;
    display: block;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .bg_inner {
    min-height: 534px;
  }
@media (min-width: 992px) {
  .back {
    height: 800px;
    margin-bottom: 0;
  }
  .bg_overlay {
    background-size: 17% auto;
  }
  .shape svg {
    width: calc(143% + 1.3px);
    height: 500px;
  }
}
</style>
