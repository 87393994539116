import { downloadRecurringDonationSummary, downloadRecurringDonationSummaryBreakdown } from '@/api/recurringDonations/download'
const FileDownload = require('js-file-download')

const actions = {
  async downloadRecurringDonationSummary ({ rootState }, id) {
    try {
      downloadRecurringDonationSummary(id).then((result) => {
        FileDownload(result.data, `${rootState.user.user.donorID}_${id}.pdf`)
      })
    } catch (error) {
      console.error('ERRR', error)
      return 'Error'
    }
  },
  async downloadRecurringDonationSummaryBreakdown ({ rootState }, id) {
    try {
      downloadRecurringDonationSummaryBreakdown(id).then((result) => {
        FileDownload(result.data, `${rootState.user.user.donorID}_${id}.pdf`)
      })
    } catch (error) {
      console.error('ERRR', error)
      return 'Error'
    }
  }
}

export default {
  actions
}
