import axios from 'axios'
const creditCardApp = (transactionRef, returnUrl, captchaToken) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/initiate/payFast/creditCard`, {
    // 'estimatedValue': 0,
    transactionReference: transactionRef,
    returnUrl,
    cancelUrl: returnUrl,
    notifyUrl: `${process.env.VUE_APP_URL_BASE}/adms/api/donations/call-back/pay-fast`,
    captchaToken
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}
export {
  creditCardApp
}
