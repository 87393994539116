import { deleteAccount, checkDeleteAccountStatus } from '@/api/deleteAccount'

const actions = {
  async deleteAccount ({ _ }, reason) {
    return await deleteAccount(reason)
  },
  async checkDeleteAccountStatus ({ _ }) {
    return await checkDeleteAccountStatus()
  }
}

export default {
  actions
}
