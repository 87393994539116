import { getUserTransactions, getOrganisationTransactions, getGroupTransactions, check18A, get18ATransactions, getTransactionReceipt, getMultipleTransactionReceipt, getTransactionReceiptSummary, getDebitOrders, getDebitOrderDetails, stopDebitOrder, downloadDebitOrder, updateDebitOrder, getDailySadaqah, downloadSection18, downloadMultipleSection18, downloadSection18Summary, emailTransactionReceipt, downloadDatesSection18, getBankOptions, getBankAllOptions } from '@/api/transactions'
const FileDownload = require('js-file-download')
const arrangeDescending = (a, b) => {
  if (a.debitOrderID < b.debitOrderID) {
    return 1
  }
  if (a.debitOrderID > b.debitOrderID) {
    return -1
  }
  return 0
}
const state = {
  transactions: [],
  userTransactions: [],
  organisationTransactions: [],
  userGroupTransactions: [],
  pledges: [],
  debitOrders: null,
  dailySadaqah: null,
  debitOrdersStatus: null,
  dailySadaqahStatus: null,
  organisationTransactionsStatus: null,
  userGroupTransactionsStatus: null,
  userTransactionsStatus: null,
  section18ATransactionsStatus: null,
  section18ATransactions: null,
  emailReceiptStatus: null,
  downloadReceiptStatus: null,
  bankOptions: null,
  bankAllOptions: null
}

const mutations = {
  setUserTransactions (state, transactions) {
    const arr = []
    if (transactions && transactions.length > 0) {
      if (state.userTransactions && state.userTransactions.length > 0) {
        transactions.forEach((transaction) => {
          if (!state.userTransactions.some(el => el.transactionID === transaction.transactionID)) {
            arr.push(transaction)
          }
        })
        state.userTransactions = arr.concat(state.userTransactions)
      } else {
        state.userTransactions = transactions
      }
    } else {
      state.userTransactions = arr
    }
  },
  setOrganisationTransactions (state, transactions) {
    const arr = []
    if (transactions && transactions.length > 0) {
      if (state.organisationTransactions && state.organisationTransactions.length > 0) {
        transactions.forEach((transaction) => {
          if (!state.organisationTransactions.some(el => el.transactionID === transaction.transactionID)) {
            arr.push(transaction)
          }
        })
        state.organisationTransactions = arr.concat(state.organisationTransactions)
      } else {
        state.organisationTransactions = transactions
      }
    } else {
      state.organisationTransactions = arr
    }
  },
  setGroupTransactions (state, transactions) {
    const arr = []
    if (transactions && transactions.length > 0) {
      if (state.userGroupTransactions && state.userGroupTransactions.length > 0) {
        transactions.forEach((transaction) => {
          if (!state.userGroupTransactions.some(el => el.transactionID === transaction.transactionID)) {
            arr.push(transaction)
          }
        })
        state.userGroupTransactions = arr.concat(state.userGroupTransactions)
      } else {
        state.userGroupTransactions = transactions
      }
    } else {
      state.userGroupTransactions = arr
    }
  },
  setTransactions (state) {
    state.transactions = state.userTransactions.concat(state.organisationTransactions, state.userGroupTransactions)
  },
  setPledges (state, transactions) {
    const arr = []
    if (transactions && transactions.length > 0) {
      if (state.pledges && state.pledges.length > 0) {
        transactions.forEach((transaction) => {
          if (!state.pledges.some(el => el.transactionID === transaction.transactionID)) {
            arr.push(transaction)
          }
        })
        state.pledges = arr.concat(state.pledges)
      } else {
        state.pledges = transactions
      }
    } else {
      state.pledges = arr
    }
  },
  setBankOptions (state, banks) {
    state.bankOptions = banks
  },
  setBankAllOptions (state, banks) {
    state.bankAllOptions = banks
  },
  setDailySadaqah (state, transactions) {
    if (transactions) {
      state.dailySadaqah = transactions
    } else {
      state.dailySadaqah = null
    }
  },
  setDebitOrders (state, transactions) {
    if (transactions) {
      state.debitOrders = transactions
    } else {
      state.debitOrders = null
    }
  },
  setDebitOrdersStatus (state, status) {
    state.debitOrdersStatus = status
  },
  setDailySadaqahStatus (state, status) {
    state.dailySadaqahStatus = status
  },
  setOrganisationTransactionsStatus (state, status) {
    state.organisationTransactionsStatus = status
  },
  setUserTransactionsStatus (state, status) {
    state.userTransactionsStatus = status
  },
  setGroupTransactionsStatus (state, status) {
    state.userGroupTransactionsStatus = status
  },
  set18ATransactions (state, transactions) {
    const arr = []
    if (transactions && transactions.length > 0) {
      if (state.section18ATransactions && state.section18ATransactions.length > 0) {
        transactions.forEach((transaction) => {
          if (!state.section18ATransactions.some(el => el.transactionID === transaction.transactionID)) {
            arr.push(transaction)
          }
        })
        state.section18ATransactions = arr.concat(state.section18ATransactions)
      } else {
        state.section18ATransactions = transactions
      }
    } else {
      state.section18ATransactions = arr
    }
  },
  set18ATransactionsStatus (state, status) {
    state.section18ATransactionsStatus = status
  },
  setEmailReceiptStatus (state, status) {
    state.emailReceiptStatus = status
  },
  setDownloadReceiptStatus (state, status) {
    state.downloadReceiptStatus = status
  }
}

const actions = {
  async setDebitOrdersStatus ({ commit }, status) {
    await commit('setDebitOrdersStatus', status)
  },
  async fetchBankOptions ({ commit }) {
    const bankOptions = await getBankOptions()
    await commit('setBankOptions', bankOptions.data)
  },
  async fetchBankAllOptions ({ commit }) {
    const bankOptions = await getBankAllOptions()
    await commit('setBankAllOptions', bankOptions.data)
  },

  async setDailySadaqahStatus ({ commit }, status) {
    await commit('setDailySadaqahStatus', status)
  },
  async setOrganisationTransactionsStatus ({ commit }, status) {
    await commit('setOrganisationTransactionsStatus', status)
  },
  async setUserTransactionsStatus ({ commit }, status) {
    await commit('setUserTransactionsStatus', status)
  },
  async setGroupTransactionsStatus ({ commit }, status) {
    await commit('setGroupTransactionsStatus', status)
  },
  async setUserTransactions ({ commit }, transactions) {
    const arr = []
    const pledges = []
    if (transactions && transactions.length > 0) {
      transactions.forEach((transaction) => {
        if ((transaction.paymentOption.paymentID !== 1 && transaction.paymentOption.paymentID !== 5) && (transaction.transactionStatus.transactionStatusID === 11 || transaction.transactionStatus.transactionStatusID === 1)) {
          pledges.push(transaction)
        } else if (transaction.transactionStatus.transactionStatusID !== 3 && transaction.transactionStatus.transactionStatusID !== 6 && transaction.transactionStatus.transactionStatusID !== 7 && transaction.transactionStatus.transactionStatusID !== 8) {
          arr.push(transaction)
        }
      })
    }
    await commit('setUserTransactions', arr)
    await commit('setPledges', pledges)
    await commit('setTransactions')
  },
  async fetchUserTransactions ({ dispatch }, start) {
    dispatch('setUserTransactionsStatus', 'loading')
    const userTransactions = await getUserTransactions(start)
    if (userTransactions && userTransactions.data) {
      await dispatch('setUserTransactions', userTransactions.data)
    }
    await dispatch('setUserTransactionsStatus', 'ready')
  },
  async set18ATransactionsStatus ({ commit }, status) {
    await commit('set18ATransactionsStatus', status)
  },
  async set18ATransactions ({ commit }, transactions) {
    await commit('set18ATransactions', transactions)
  },
  async fetch18ATransactions ({ dispatch }, id) {
    dispatch('set18ATransactionsStatus', 'loading')
    const user18ATransactions = await get18ATransactions()
    await dispatch('set18ATransactions', user18ATransactions.data)
    await dispatch('set18ATransactionsStatus', 'ready')
  },
  async check18A ({ _ }, id) {
    const check18AResponse = await check18A(id)
    return check18AResponse.data
  },
  async downloadSection18 ({ rootState }, id) {
    await downloadSection18(id).then((receipt) => {
      FileDownload(receipt.data, `18A-${rootState.user.user.donorID}-${id}.pdf`)
    })
  },
  async downloadMultipleSection18 ({ _ }, ids) {
    await downloadMultipleSection18(ids).then((receipt) => {
      FileDownload(receipt.data, 'Alim-TaxCertificate.zip')
    })
  },
  async downloadDatesSection18 ({ _ }, dates) {
    await downloadDatesSection18(dates).then((receipt) => {
      if (receipt.headers['content-type'] === 'application/pdf') {
        FileDownload(receipt.data, `Alim-TaxCertificate${dates.startDate}_${dates.endDate}.pdf`)
      }
      if (receipt.headers['content-type'] === 'application/zip') {
        FileDownload(receipt.data, `Alim-TaxCertificate${dates.startDate}_${dates.endDate}.zip`)
      }
    })
  },
  async downloadSection18Summary ({ _ }, id) {
    await downloadSection18Summary().then((receipt) => {
      if (receipt.headers['content-type'] === 'application/pdf') {
        FileDownload(receipt.data, 'Alim-TaxCertificateSummary.pdf')
      }
      if (receipt.headers['content-type'] === 'application/zip') {
        FileDownload(receipt.data, 'Alim-TaxCertificateSummary.zip')
      }
    })
  },
  async setOrganisationTransactions ({ commit }, transactions) {
    const arr = []
    const pledges = []
    if (transactions && transactions.length > 0) {
      transactions.forEach((transaction) => {
        if ((transaction.paymentOption.paymentID !== 1 && transaction.paymentOption.paymentID !== 5) && (transaction.transactionStatus.transactionStatusID === 11 || transaction.transactionStatus.transactionStatusID === 1)) {
          pledges.push(transaction)
        } else if (transaction.transactionStatus.transactionStatusID !== 3 && transaction.transactionStatus.transactionStatusID !== 6 && transaction.transactionStatus.transactionStatusID !== 7 && transaction.transactionStatus.transactionStatusID !== 8) {
          arr.push(transaction)
        }
      })
    }
    await commit('setOrganisationTransactions', arr)
    await commit('setPledges', pledges)
    await commit('setTransactions')
  },
  async fetchOrganisationTransactions ({ dispatch }, id) {
    dispatch('setOrganisationTransactionsStatus', 'loading')
    const orgTransactions = await getOrganisationTransactions(id)
    await dispatch('setOrganisationTransactions', orgTransactions.data)
    await dispatch('setOrganisationTransactionsStatus', 'ready')
  },

  async setGroupTransactions ({ commit }, transactions) {
    const arr = []
    const pledges = []
    if (transactions && transactions.length > 0) {
      transactions.forEach((transaction) => {
        if ((transaction.paymentOption.paymentID !== 1 && transaction.paymentOption.paymentID !== 5) && (transaction.transactionStatus.transactionStatusID === 11 || transaction.transactionStatus.transactionStatusID === 1)) {
          pledges.push(transaction)
        } else if (transaction.transactionStatus.transactionStatusID !== 3 && transaction.transactionStatus.transactionStatusID !== 6 && transaction.transactionStatus.transactionStatusID !== 7 && transaction.transactionStatus.transactionStatusID !== 8) {
          arr.push(transaction)
        }
      })
    }
    await commit('setGroupTransactions', arr)
    await commit('setPledges', pledges)
    await commit('setTransactions')
  },
  async fetchGroupTransactions ({ dispatch }, id) {
    dispatch('setGroupTransactionsStatus', 'loading')
    const userGroupTransactions = await getGroupTransactions(id)
    await dispatch('setGroupTransactions', userGroupTransactions.data)
    await dispatch('setGroupTransactionsStatus', 'ready')
  },
  async printTransactionReceipt ({ _ }, id) {
    await getTransactionReceipt(id).then((receipt) => {
      const url = URL.createObjectURL(receipt.data)
      window.open(url)
    })
  },
  async downloadTransactionReceipt ({ dispatch }, transaction) {
    await dispatch('setDownloadReceiptStatus', 'downloading')
    let donorId = `AIF${transaction.userID}`
    if (transaction.organisationID) {
      donorId = transaction.organisationID
    }
    await getTransactionReceipt(transaction.transactionID).then((receipt) => {
      FileDownload(receipt.data, `${donorId}_${transaction.transactionReference}.pdf`)
    })
    await dispatch('setDownloadReceiptStatus', 'downloaded')
    setTimeout(() => {
      dispatch('setDownloadReceiptStatus', null)
    }, 2000)
  },
  async setEmailReceiptStatus ({ commit }, status) {
    await commit('setEmailReceiptStatus', status)
  },
  async setDownloadReceiptStatus ({ commit }, status) {
    await commit('setDownloadReceiptStatus', status)
  },
  async emailTransactionReceipt ({ dispatch }, id) {
    await dispatch('setEmailReceiptStatus', 'sending')
    await emailTransactionReceipt(id)
    await dispatch('setEmailReceiptStatus', 'sent')
    setTimeout(() => {
      dispatch('setEmailReceiptStatus', null)
    }, 2000)
  },
  async printSummaryAll ({ _ }, id) {
    await getTransactionReceiptSummary().then((receipt) => {
      const url = URL.createObjectURL(receipt.data)
      window.open(url)
    })
  },
  async downloadSummaryAll ({ _ }, id) {
    await getTransactionReceiptSummary(id).then((receipt) => {
      FileDownload(receipt.data, 'Alim-Summary.pdf')
    })
  },
  async downloadMultipleTransactionReceipt ({ _ }, IDs) {
    await getMultipleTransactionReceipt(IDs)
  },
  async setUserDebitOrders ({ commit }, transactions) {
    await commit('setDebitOrders', transactions)
  },
  async fetchUserDebitOrders ({ dispatch }) {
    dispatch('setDebitOrdersStatus', 'loading')
    const debitOrders = await getDebitOrders()
    const arr = []
    if (debitOrders.data) {
      debitOrders.data.forEach(order => {
        if ((order.debitOrderStatus.debitOrderStatusID === 1 || order.debitOrderStatus.debitOrderStatusID === 2) && !order.dailySadaqa) {
          arr.push(order)
        }
      })
    }
    arr.sort(arrangeDescending)
    await dispatch('setUserDebitOrders', arr)
    await dispatch('setDebitOrdersStatus', 'ready')
  },
  async fetchDebitOrderDetails ({ _ }, id) {
    const details = await getDebitOrderDetails(id)
    return details
  },
  async stopDebitOrder ({ _ }, id) {
    await stopDebitOrder(id)
  },
  async updateDebitOrder ({ dispatch }, debitOrder) {
    await dispatch('setDebitOrdersStatus', 'updating')
    await updateDebitOrder(debitOrder)
    await dispatch('setDebitOrdersStatus', 'updated')
    await dispatch('fetchUserDebitOrders')
  },
  async downloadDebitOrderDetails ({ _ }, id) {
    await downloadDebitOrder(id).then((receipt) => {
      FileDownload(receipt.data, `Alim-Receipt${id}.pdf`)
    })
  },
  async setUserDailySadaqah ({ commit }, transactions) {
    await commit('setDailySadaqah', transactions)
  },
  async fetchUserDailySadaqah ({ dispatch }) {
    dispatch('setDailySadaqahStatus', 'loading')
    const dailySadaqah = await getDailySadaqah()
    await dispatch('setUserDailySadaqah', dailySadaqah.data)
    await dispatch('setDailySadaqahStatus', 'ready')
  }
}

const getters = {
  transactions: state => state.transactions,
  userTransactions: state => state.userTransactions,
  organisationTransactions: state => state.organisationTransactions,
  userGroupTransactions: state => state.userGroupTransactions,
  debitOrders: state => state.debitOrders,
  dailySadaqah: state => state.dailySadaqah,
  debitOrdersStatus: state => state.debitOrdersStatus,
  dailySadaqahStatus: state => state.dailySadaqahStatus,
  organisationTransactionsStatus: state => state.organisationTransactionsStatus,
  userGroupTransactionsStatus: state => state.userGroupTransactionsStatus,
  userTransactionsStatus: state => state.userTransactionsStatus,
  section18ATransactionsStatus: state => state.section18ATransactionsStatus,
  bankOptions: state => state.bankOptions,
  bankAllOptions: state => state.bankAllOptions,
  pledges: state => state.pledges,
  emailReceiptStatus: state => state.emailReceiptStatus,
  downloadReceiptStatus: state => state.downloadReceiptStatus,
  section18ATransactions: state => {
    if (state.section18ATransactions && state.section18ATransactions.length > 0) {
      state.section18ATransactions.sort((a, b) => {
        return b.transactionDate - a.transactionDate
      })
    }
    return state.section18ATransactions
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
