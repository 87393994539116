import axios from 'axios'
const initiateStripe = (transactionReference, returnUrl, captchaToken) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/initiate/stripe/creditCard`, {
    transactionReference,
    returnUrl,
    captchaToken
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}
const cancelStripe = (transactionReference, paymentIntentID) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/cancel-payment/stripe/creditCard`, {
    transactionReference,
    paymentIntentID
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}
const verifyStripe = (transactionReference, paymentIntentID) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/creditCard/stripe/verify`, {
    transactionReference,
    paymentIntentID
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}

export {
  initiateStripe,
  cancelStripe,
  verifyStripe
}
