import { initiateStripe, cancelStripe, verifyStripe } from '@/api/stripe'

const PAYMENT_COMPLETE_URL = `${location.protocol}//${location.host}/%23/paymentcompleteUk`
const state = {
  stripeClientSecret: null,
  stripePaymentIntentID: null
}
const mutations = {
  setClientSecret (state, val) {
    state.stripeClientSecret = val
  },
  setPaymentIntentID (state, val) {
    state.stripePaymentIntentID = val
  }
}

const actions = {
  async initiateStripePayment ({ commit, rootState }, captchaToken) {
    const details = await initiateStripe(rootState.cart.lastTransactionRef, PAYMENT_COMPLETE_URL, captchaToken)
    if (details) {
      commit('setClientSecret', details.data.stripePaymentIntentCreation.client_secret)
      commit('setPaymentIntentID', details.data.stripePaymentIntentCreation.id)
      return details.data
    }
  },
  async cancelStripePayment ({ commit, state, rootState }) {
    const details = await cancelStripe(rootState.cart.lastTransactionRef, state.stripePaymentIntentID)
    commit('setClientSecret', null)
    commit('setPaymentIntentID', null)
    return details.data
  },
  async verifyStripePayment ({ state, rootState }) {
    const details = await verifyStripe(rootState.cart.lastTransactionRef, state.stripePaymentIntentID)
    return details.data
  }
}
const getters = {
  stripeClientSecret: state => state.stripeClientSecret,
  stripePaymentIntentID: state => state.stripePaymentIntentID
}
export default {
  state,
  mutations,
  actions,
  getters
}
