const state = {
  activeSlider: {},
  showOptions: true,
  isInteracted: false
}

const mutations = {
  setActiveSlider (state, slider) {
    state.showOptions = false
    if (slider) {
      state.activeSlider = slider
      setTimeout(() => {
        state.showOptions = true
      }, 50)
    }
  },
  setInteracted (state, interacted) {
    state.isInteracted = interacted
  }
}

const actions = {
  setActiveSlider ({ commit }, slider) {
    commit('setActiveSlider', slider)
  },
  setInteracted ({ commit }, interacted) {
    commit('setInteracted', interacted)
  }
}

const getters = {
  activeSlider: state => state.activeSlider,
  showOptions: state => state.showOptions,
  isInteracted: state => state.isInteracted
}

export default {
  state,
  mutations,
  actions,
  getters
}
