import axios from 'axios'
import store from '../store'

const deleteAccount = async (reason) => {
  return axios.delete(`${process.env.VUE_APP_URL_BASE}/adms/api/users/account/deletion?reason=${reason}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }).then((res) => {
    return res
  }).catch((error) => {
    console.log('ERROR', error)
    return error
  })
}
const checkDeleteAccountStatus = async () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/users/account/status/deleted`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }).then((res) => {
    return res
  }).catch((error) => {
    console.log('ERROR', error)
    return error
  })
}
export { checkDeleteAccountStatus, deleteAccount }
