import axios from 'axios'
import store from '../store'

const getUserTransactions = (start) => {
  const pageSize = 100
  const startPosition = start || 0
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/users/find/${store.state.user.user.userID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    },
    params: {
      startPosition,
      pageSize,
      sortField: 'transactionDate',
      sortOrder: 'DESC'
    }
  })
}
const getOrganisationTransactions = (organisationId, start, size) => {
  const startPosition = start || 0
  const pageSize = size || 100
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/organisations/find/${organisationId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    },
    params: {
      startPosition,
      pageSize,
      sortField: 'transactionDate',
      sortOrder: 'DESC'
    }
  })
}
const getGroupTransactions = (groupId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/transactions/${groupId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const check18A = (transactionID) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/v2/canDownload/${transactionID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const get18ATransactions = (startPosition = 0, pageSize = 100) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/taxCertificate/find/${store.state.user.user.userID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    },
    params: {
      startPosition,
      pageSize,
      sortField: 'transactionDate',
      sortOrder: 'DESC'
    }
  })
}
const getTransactionReceipt = (transactionId) => {
  let headers = {}
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/v2/receipts/download/${transactionId}`, {
    headers,
    responseType: 'blob'
  })
}
const emailTransactionReceipt = (transactionId) => {
  let headers = {}
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/receipts/email/${transactionId}`, {
    headers,
    responseType: 'blob'
  })
}
const getMultipleTransactionReceipt = (transactionIDs) => {
  let headers = {}
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/receipt/selection/download`, { transactionIDs }, {
    headers,
    responseType: 'blob'
  })
}
const getTransactionReceiptSummary = () => {
  let headers = {}
  const user = {
    userID: store.state.user.user.userID
  }
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/receipt/summary/all`, user, {
    headers,
    responseType: 'blob'
  })
}

const downloadSection18 = (transactionId) => {
  let headers = {}
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/v2/taxCertificate/download/${transactionId}`, {
    headers,
    responseType: 'blob'
  })
}
const downloadMultipleSection18 = (transactionIDs) => {
  let headers = {}
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/v2/taxCertificate/selection/download`, transactionIDs, {
    headers,
    responseType: 'blob'
  })
}
const downloadDatesSection18 = (dates) => {
  let headers = {}
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/v2/taxCertificate/between/dates`, dates, {
    headers,
    responseType: 'blob'
  })
}
const downloadSection18Summary = () => {
  let headers = {}
  const user = {
    userID: store.state.user.user.userID
  }
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/v2/taxCertificate/summary/all`, user, {
    headers,
    responseType: 'blob'
  })
}
const getDebitOrders = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/user/${store.state.user.user.userID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getBankOptions = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/banks/universal/all`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getBankAllOptions = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/banks/all`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getDailySadaqah = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/dailySadaqa/user/${store.state.user.user.userID}?startPosition=1&pageSize=100&sortField=transactionID&sortOrder=ASC`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getDebitOrderDetails = (debitID) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/${debitID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const stopDebitOrder = (debitID) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/stop/${debitID}?reason=stopitfromsite`, {}, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const downloadDebitOrder = (debitID) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/summary/breakdown/download/${debitID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    },
    responseType: 'blob'
  })
}
const updateDebitOrder = (debitOrder) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/za/update`, debitOrder, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
export {
  getUserTransactions,
  getOrganisationTransactions,
  check18A,
  get18ATransactions,
  getTransactionReceipt,
  emailTransactionReceipt,
  getMultipleTransactionReceipt,
  getTransactionReceiptSummary,
  getDebitOrders,
  getDebitOrderDetails,
  stopDebitOrder,
  downloadDebitOrder,
  updateDebitOrder,
  getDailySadaqah,
  downloadSection18,
  downloadMultipleSection18,
  downloadSection18Summary,
  downloadDatesSection18,
  getGroupTransactions,
  getBankOptions,
  getBankAllOptions
}
