import { creditCardApp } from '@/api/appPayment'
const PAYMENT_COMPLETE_PAYFAST_URL = 'https://s3.us-east-2.amazonaws.com/aiappstatic.co.za/done.html'

const actions = {
  async payApp ({ rootState }, token) {
    const details = await creditCardApp(rootState.cart.lastTransactionRef, PAYMENT_COMPLETE_PAYFAST_URL, token)
    if (details.data) {
      return details.data
    }
  }
}

export default {
  actions
}
