import axios from 'axios'
import store from '../store'

const login = async (auth) => {
  try {
    const loginResponse = await axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/user/v2/login`, {}, { auth, headers: { baseSystemType: 'PUBLIC_WEBSITE' } })
    return loginResponse
  } catch (error) {
    const loginResponse = 'error'
    return loginResponse
  }
}

const resetPassword = (email) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/v2/password/reset?email=${email}`)
}
const resetPasswordVerify = (details) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/password/verify`, details, {
    headers: {
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const registerDonor = (donor) => {
  let address = donor.address
  if (address !== null) {
    address = {
      addressType: 'PHYSICAL_ADDRESS',
      addressLine1: donor.primaryAddress.addressLine1,
      addressLine2: donor.primaryAddress.addressLine2 || '',
      town: donor.primaryAddress.town,
      postalCode: donor.primaryAddress.postalCode,
      country: donor.primaryAddress.country,
      primaryAddress: true
    }
  }
  const details = {
    email: donor.email,
    userTitleId: donor.userTitleId,
    idNumber: donor.idNumber,
    passportNumber: donor.passportNumber,
    countryOfIssueID: donor.countryOfIssueID,
    individualTaxNumber: donor.individualTaxNumber,
    firstName: donor.firstName,
    lastName: donor.lastName,
    cell: donor.cell,
    telHome: donor.telHome,
    zakaatCalculationDate: donor.zakaatCalculationDate,
    primaryAddress: address,
    correspondence: donor.correspondence,
    giftAid: donor.giftAid,
    credentials: {
      username: donor.username,
      password: donor.password,
      confirmPassword: donor.confirmPassword
    }
  }
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/registration/v2/donor`, details, { headers: { baseSystemType: 'PUBLIC_WEBSITE' } })
}
const getUserInfo = (email) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/users/donor/?aifID=${email}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const findUser = (details) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/donor/search`, details, {
    headers: {
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const checkUserExists = (email) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/users/v2/donor/exists?aifID=${email}`, {
    headers: {
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const updateCredentials = (user) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/v2/credentials`, user, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const updateDetails = (user) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/v2/details`, user, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const updatePrimaryAddress = async (user) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/v2/primary/address`, user, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const updateSecondaryAddress = async (user) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/v2/secondaryAddress/${store.state.user.user.userID}`, user, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const updateSecondaryEmail = async (user) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/v2/additional/contact/${store.state.user.user.userID}`, user, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const updateSecondaryPhone = async (user) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/v2/additional/contact/${store.state.user.user.userID}`, user, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const getTransactions = (userId, token, start, size) => {
  const startPosition = start || 0
  const pageSize = size || 100
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/users/find/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      startPosition,
      pageSize,
      sortField: 'transactionDate',
      sortOrder: 'DESC'
    }
  })
}

const setVolunteer = (user) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/volunteer/withProfile/add`, user, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const setAnonVolunteer = (user) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/volunteer/add`, user, {
    headers: {
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getUserDonors = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donors/all`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const addProfileImage = (profileImg) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/users/user/upload?base64String=${profileImg}`, {}, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const verifyIDNumber = (idNumber) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/registration/validateIDNumber/${idNumber}`, {
    headers: {
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
export { login, registerDonor, findUser, getUserInfo, updateCredentials, updateDetails, updatePrimaryAddress, updateSecondaryAddress, updateSecondaryEmail, updateSecondaryPhone, resetPassword, resetPasswordVerify, getTransactions, setVolunteer, setAnonVolunteer, getUserDonors, addProfileImage, checkUserExists, verifyIDNumber }
