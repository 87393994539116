import { saveRecurringDonation, initiatePayFastSubscription, initiateStripeSubscription } from '@/api/recurringDonations/save'

const state = {
  stripeSubscriptionClientSecret: null,
  stripeSubscriptionPaymentIntentID: null
}
const mutations = {
  setSubscriptionClientSecret (state, val) {
    state.stripeSubscriptionClientSecret = val
  },
  setSubscriptionPaymentIntentID (state, val) {
    state.stripeSubscriptionPaymentIntentID = val
  }
}
const actions = {
  async saveRecurringDonation ({ _ }, val) {
    try {
      const saveReturn = await saveRecurringDonation(val)
      return saveReturn
    } catch (error) {
      console.error('ERRR', error)
    }
  },
  async initiatePayfast ({ _ }, postData) {
    const returnUrl = `${location.protocol}//${location.host}/recurringpaymentcomplete/${postData.transactionRef}/${postData.recurringDonationID}`
    const details = await initiatePayFastSubscription(postData.transactionRef, returnUrl, postData.token)
    if (details.data) {
      return details.data
    }
  },
  async initiateStripeSubscription ({ commit }, postData) {
    const returnUrl = `${location.protocol}//${location.host}/recurringpaymentcompleteUk/${postData.transactionRef}`
    const details = await initiateStripeSubscription(postData.transactionRef, returnUrl, postData.token)
    if (details.data && details.data.stripeSubscription && details.data.stripeSubscription.id && details.data.stripeSubscription.pending_setup_intent && details.data.stripeSubscription.pending_setup_intent.id && details.data.stripeSubscription.pending_setup_intent.client_secret) {
      commit('setSubscriptionClientSecret', details.data.stripeSubscription.pending_setup_intent.client_secret)
      commit('setSubscriptionPaymentIntentID', details.data.stripeSubscription.pending_setup_intent.id)
      return details.data
    }
  }
}
const getters = {
  stripeSubscriptionClientSecret: state => state.stripeSubscriptionClientSecret,
  stripeSubscriptionPaymentIntentID: state => state.stripeSubscriptionPaymentIntentID
}
export default {
  state,
  mutations,
  actions,
  getters
}
